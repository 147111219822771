import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import { useGetProductByIdQuery } from "../../services/productApi";
import { addToCart, addToWishList } from "../../state";
import AlsoProducts from "./AlsoProducts/AlsoProducts";
import ProductDescription from "./ProductDescription";
// import Swiper core and required modules
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import { toast } from "react-toastify";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const ProductDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const [selectedSize, setSelectedSize] = useState(null);
  const cart = useSelector((state) => state.cart.cart);
  const wishList = useSelector((state) => state.cart.wishList);
  const { data: product, isLoading } = useGetProductByIdQuery(id);
  const [selectedImage, setSelectedImage] = useState("");
  // const [wishList, setWishList] = useState(
  //   JSON.parse(localStorage.getItem("wishlist")) || []
  // );

  useEffect(() => {
    setSelectedImage(product?.data?.images[0]);
  }, [product?.data?.images]);

  useEffect(() => {
    if (wishList?.length > 0) {
      localStorage.setItem("wishlist", JSON.stringify(wishList));
    }
  }, [wishList]);

  useEffect(() => {
    if (cart?.length > 0) {
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }, [cart]);

  const checkExist = (item, arr) => {
    for (const i of arr) {
      if (i?._id === item?._id) {
        return true;
      }
    }
    return false;
  };

  const handleAddToClothes = () => {
    if (checkExist(product?.data, cart)) {
      toast.error("This product was already added!");
    } else {
      dispatch(
        addToCart({
          item: {
            ...product?.data,
            counter: {
              name: selectedSize?.name,
              quantity: count,
            },
          },
        })
      );
      localStorage.setItem("cart", JSON.stringify(cart));
      toast.success("Product was added to Cart!");
    }
  };

  const handleAddToCart = () => {
    if (checkExist(product?.data, cart)) {
      toast.error("This product was already added!");
    } else {
      dispatch(addToCart({ item: { ...product?.data, count } }));
      localStorage.setItem("cart", JSON.stringify(cart));
      toast.success("Product was added to Cart!");
    }
  };

  const handleAddSave = () => {
    if (checkExist(product?.data, wishList)) {
      toast.error("This product was already added to Wishlist!");
    } else {
      dispatch(addToWishList({ item: { ...product?.data } }));
      localStorage.setItem("wishlist", JSON.stringify(wishList));
      toast.success("Product was added to wishlist!");
    }
  };

  const getClothesQuantity = (cquantity) => {
    let total = 0;
    for (const i of cquantity) {
      total += i.quantity;
    }
    return total;
  };

  return (
    <div className="bg-[#F7F7F7] md:px-0 w-full pt-[30px]">
      {isLoading ? (
        <Loading></Loading>
      ) : (
        <>
          <div className="max-w-[1440px] mx-auto pb-[100px] px-3 md:px-14 ">
            <div className="bg-[white] p-[15px] rounded flex flex-col md:flex-row justify-center mb-[120px] gap-[20px] ">
              {/* left side - photo section */}
              <div className="flex flex-col items-center text-center w-full md:w-[40%]">
                <div className="w-[100%]">
                  <div className="w-[100%] product-details">
                    <Swiper
                      // install Swiper modules
                      modules={[Navigation, Pagination, Scrollbar, A11y]}
                      spaceBetween={50}
                      slidesPerView={1}
                      navigation
                      pagination={{ clickable: true }}
                      // scrollbar={{ draggable: true }}
                      onSwiper={(swiper) => console.log(swiper)}
                      onSlideChange={() => console.log("slide change")}
                    >
                      {product?.data?.images?.map((d) => (
                        <SwiperSlide>
                          <div className="flex justify-center items-center">
                            <img
                              src={d}
                              alt="d"
                              className="w-[60%] h-[270px] object-contain md:w-[80%] md:h-[100%]"
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </div>

              {/* right side - details and add cart section */}
              <div className="w-full px-4 md:w-[55%] flex flex-col justify-between">
                <div>
                  <p className="font-bold text-[24px]">{product?.data?.name}</p>
                  <p className="text-gray-500">
                    <span className="font-bold mr-2 text-sm">Brand:</span>{" "}
                    {product?.data?.brand}
                  </p>
                  <p className="text-gray-500">
                    <span className="font-bold mr-2 text-sm">Condition:</span>{" "}
                    {product?.data?.condition
                      ? product?.data?.condition
                      : "Not Available"}
                  </p>
                  {product?.data?.category === "fashion" &&
                  product?.data?.subCategory === "clothes" ? (
                    <>
                      {getClothesQuantity(product?.data?.cquantity) !== 0 && (
                        <>
                          <p className="text-gray-500">
                            <span className="font-bold mr-2 text-sm">
                              Size and Quantity:
                            </span>{" "}
                            {product?.data?.cquantity?.map((q) => (
                              <>
                                {q?.quantity > 0 && (
                                  <span className="uppercase">
                                    {q?.name}={q?.quantity},{" "}
                                  </span>
                                )}
                              </>
                            ))}
                          </p>
                          <div className="my-[10px]">
                            <p className="text-gray-500 font-bold mr-2 text-[16px] mb-[5px]">
                              {" "}
                              Select Size:{" "}
                            </p>
                            <div className="flex justify-start items-center">
                              {" "}
                              {product?.data?.cquantity?.map((q) => (
                                <>
                                  {q?.quantity > 0 && (
                                    <div
                                      onClick={() => {
                                        if (q?.name === selectedSize?.name) {
                                          setSelectedSize(null);
                                        } else {
                                          setSelectedSize(q);
                                        }
                                      }}
                                      className={
                                        q?.name === selectedSize?.name
                                          ? "h-[50px] w-[50px] text-[12px] flex justify-center items-center border-[1px] mr-[10px]  bg-green-400 text-white cursor-pointer uppercase"
                                          : "h-[50px] w-[50px] text-[12px] flex justify-center items-center border-[1px]  mr-[10px] cursor-pointer uppercase"
                                      }
                                    >
                                      {q?.name}
                                    </div>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <p className="text-gray-500">
                      <span className="font-bold mr-2 text-sm">Quantity:</span>{" "}
                      {product?.data?.quantity
                        ? product?.data?.quantity
                        : "Not Available"}
                    </p>
                  )}
                </div>
                {/* <p className="mt-[40px] my-[40px]">{product?.data?.description}</p> */}
                <ProductDescription description={product?.data?.description} />

                <div>
                  {product?.data?.category === "fashion" &&
                  product?.data?.subCategory === "clothes" ? (
                    <>
                      {getClothesQuantity(product?.data?.cquantity) === 0 ? (
                        <p className="mt-[16px] md:mt-[20px] text-3xl font-semibold art-text-primary mb-[16px] md:mb-[50px]">
                          Out of Stock
                        </p>
                      ) : (
                        <p className="mt-[16px] md:mt-[20px] text-3xl font-semibold art-text-primary mb-[16px] md:mb-[50px]">
                          £{product?.data?.price}
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      {product?.data?.quantity === 0 ? (
                        <p className="mt-[16px] md:mt-[20px] text-3xl font-semibold art-text-primary mb-[16px] md:mb-[50px]">
                          Out of Stock
                        </p>
                      ) : (
                        <p className="mt-[16px] md:mt-[20px] text-3xl font-semibold art-text-primary mb-[16px] md:mb-[50px]">
                          £{product?.data?.price}
                        </p>
                      )}
                    </>
                  )}

                  {product?.data?.category === "fashion" &&
                  product?.data?.subCategory === "clothes" ? (
                    <>
                      {getClothesQuantity(product?.data?.cquantity) > 0 && (
                        <>
                          <div className="flex items-center">
                            {/* quantity calculation  */}
                            {selectedSize ? (
                              <>
                                {count > 1 ? (
                                  <button
                                    onClick={() =>
                                      setCount(Math.max(count - 1, 0))
                                    }
                                    className="bg-[#DF5645] hover:bg-[#fa4830] text-white w-[30px] h-[30px] 2xl:w-[40px] 2xl:h-[40px] px-auto py-auto rounded-md"
                                  >
                                    -
                                  </button>
                                ) : (
                                  <button className="bg-gray-200 text-gray-600 w-[30px] h-[30px] 2xl:w-[40px] 2xl:h-[40px] px-auto py-auto rounded-md">
                                    -
                                  </button>
                                )}
                              </>
                            ) : (
                              <>
                                <button
                                  onClick={() => {
                                    return;
                                  }}
                                  className="bg-gray-200 text-gray-600 w-[30px] h-[30px] 2xl:w-[40px] 2xl:h-[40px] px-auto py-auto rounded-md"
                                >
                                  -
                                </button>
                              </>
                            )}
                            <p className="text-2xl mx-4">{count}</p>
                            {selectedSize ? (
                              <>
                                {count < selectedSize?.quantity ? (
                                  <button
                                    onClick={() => setCount(count + 1)}
                                    className="bg-[#DF5645] hover:bg-[#fa4830] text-white w-[30px] h-[30px] 2xl:w-[40px] 2xl:h-[40px] px-auto py-auto rounded-md"
                                  >
                                    +
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => {
                                      return;
                                    }}
                                    className="bg-gray-200 text-gray-600 w-[30px] h-[30px] 2xl:w-[40px] 2xl:h-[40px] px-auto py-auto rounded-md"
                                  >
                                    +
                                  </button>
                                )}
                              </>
                            ) : (
                              <>
                                <button className="bg-gray-200 text-gray-600 w-[30px] h-[30px] 2xl:w-[40px] 2xl:h-[40px] px-auto py-auto rounded-md">
                                  +
                                </button>
                              </>
                            )}
                          </div>
                        </>
                      )}

                      <div className="max-w-xl">
                        {getClothesQuantity(product?.data?.cquantity) > 0 ? (
                          <>
                            {selectedSize ? (
                              <>
                                <button
                                  onClick={handleAddToClothes}
                                  className="mt-4 art-bg-primary text-white px-4 py-[10px] 2xl:py-[16px] bg-[#fa4830] hover:bg-[#fa4830] w-full rounded-md"
                                >
                                  Add to Cart
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  onClick={() =>
                                    toast.error("Please select a size!")
                                  }
                                  className="mt-4 art-bg-primary text-white px-4 py-[10px] 2xl:py-[16px] bg-[#fa4830] hover:bg-[#fa4830] w-full rounded-md"
                                >
                                  Add to Cart
                                </button>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <button
                              disabled
                              className="mt-2 art-bg-primary text-white px-4 py-[10px] 2xl:py-[16px] bg-[#b797939e]  w-full rounded-md"
                            >
                              Add to Cart
                            </button>
                          </>
                        )}
                        <button
                          onClick={handleAddSave}
                          className="mt-2 bg-white art-text-primary px-4 py-[10px] 2xl:py-[16px]  hover:bg-[#ffdbd6] w-full rounded-md border art-border-primary"
                        >
                          Save
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      {product?.data?.quantity > 0 && (
                        <div className="flex items-center">
                          {/* quantity calculation  */}
                          {count > 1 ? (
                            <button
                              onClick={() => setCount(Math.max(count - 1, 0))}
                              className="bg-[#DF5645] hover:bg-[#fa4830] text-white w-[30px] h-[30px] 2xl:w-[40px] 2xl:h-[40px] px-auto py-auto rounded-md"
                            >
                              -
                            </button>
                          ) : (
                            <button className="bg-gray-200 text-gray-600 w-[30px] h-[30px] 2xl:w-[40px] 2xl:h-[40px] px-auto py-auto rounded-md">
                              -
                            </button>
                          )}
                          <p className="text-2xl mx-4">{count}</p>
                          {count < product?.data?.quantity ? (
                            <button
                              onClick={() => setCount(count + 1)}
                              className="bg-[#DF5645] hover:bg-[#fa4830] text-white w-[30px] h-[30px] 2xl:w-[40px] 2xl:h-[40px] px-auto py-auto rounded-md"
                            >
                              +
                            </button>
                          ) : (
                            <button className="bg-gray-200 text-gray-600 w-[30px] h-[30px] 2xl:w-[40px] 2xl:h-[40px] px-auto py-auto rounded-md">
                              +
                            </button>
                          )}
                        </div>
                      )}

                      <div className="max-w-xl mt-4">
                        {product?.data?.quantity > 0 ? (
                          <button
                            onClick={handleAddToCart}
                            className="mt-2 art-bg-primary text-white px-4 py-[10px] 2xl:py-[16px] bg-[#fa4830] hover:bg-[#fa4830] w-full rounded-md"
                          >
                            Add to Cart
                          </button>
                        ) : (
                          <button
                            disabled
                            className="mt-2 art-bg-primary text-white px-4 py-[10px] 2xl:py-[16px] bg-[#b797939e]  w-full rounded-md"
                          >
                            Add to Cart
                          </button>
                        )}

                        <button
                          onClick={handleAddSave}
                          className="mt-2 bg-white art-text-primary px-4 py-[10px] 2xl:py-[16px]  hover:bg-[#ffdbd6] w-full rounded-md border art-border-primary"
                        >
                          Save
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* Also products you may like  */}
            <AlsoProducts cat={product?.data?.category} />
          </div>

          {/* newsletter subscribe  */}
          {/* <Newsletter /> */}
        </>
      )}
    </div>
  );
};

export default ProductDetails;
