import moment from "moment";
import React from "react";
import { FaUserCircle } from "react-icons/fa";
import { FaStar } from "react-icons/fa6";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import depop from "../../assets/e-commerce/depop.png";
import ebay from "../../assets/e-commerce/ebay.png";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { googleReview } from "./googleReview";
const Reviews = ({ data }) => {
  console.log(data);
  return (
    <div className="max-w-[1440px] mx-auto mt-[120px] relative reviews"  id="testimonials">
      <div className="pb-[30px] md:pb-[10px]">
        <p className="uppercase text-[#000000c2] text-2xl md:text-4xl font-medium my-[50px] text-center">
          Client Testimonials
        </p>
        <div className="w-full  overflow-hidden">
          <Swiper
            navigation={true}
            modules={[Navigation]}
            className=" h-[280px] px-[50px]"
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
            }}
          >
            {[...data, ...googleReview].map((review, index) => (
              <SwiperSlide
                key={index}
                // className="shadow-md rounded-md w-[70%] md:w-[80%] mx-auto h-[220px] p-[15px]"
              >
                <div
                  // onClick={() => navigate(`/product-details/${product?._id}`)}
                  className="shadow-md rounded-md w-[70%] md:w-[78%] mx-auto h-[240px] p-[15px]"
                  key={index}
                >
                  <div className="flex justify-start items-start gap-[10px] mb-[12px]">
                    <div>
                      <FaUserCircle className="text-[28px]" />
                    </div>
                    <div>
                      <p className="font-semibold">
                        {(review?.type === "ebay" ||
                          review?.type === "google") && (
                          <>
                            {review?.buyerName ? review?.buyerName : "No Name"}
                          </>
                        )}
                        {review?.type === "depop" && (
                          <>
                            {review?.buyerName ? review?.buyerName : "No Name"}
                          </>
                        )}
                      </p>
                      <p className="text-[12px]">
                        {review?.type === "ebay" || review?.type === "google"
                          ? review?.leftBefore
                          : moment(review?.leftBefore).fromNow()}
                      </p>
                    </div>
                  </div>
                  <div>
                    {review?.type === "google" && (
                      <>
                        <div className="flex justify-start items-center gap-[3px]">
                          <FaStar className="text-[20px] text-[#fad102]" />
                          <FaStar className="text-[20px] text-[#fad102]" />
                          <FaStar className="text-[20px] text-[#fad102]" />
                          <FaStar className="text-[20px] text-[#fad102]" />
                          <FaStar className="text-[20px] text-[#fad102]" />
                        </div>
                      </>
                    )}
                    {review?.type === "ebay" && (
                      <>
                        {review?.rating === "POSITIVE" && (
                          <div className="flex justify-start items-center gap-[3px]">
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                          </div>
                        )}
                        {review?.rating === "NUTRAL" && (
                          <div className="flex justify-start items-center gap-[3px]">
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                          </div>
                        )}
                        {review?.rating === "NEUTRAL" && (
                          <div className="flex justify-start items-center gap-[3px]">
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[gray]" />
                            <FaStar className="text-[20px] text-[gray]" />
                          </div>
                        )}
                        {review?.rating === "NEGATIVE" && (
                          <div className="flex justify-start items-center gap-[3px]">
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[gray]" />
                            <FaStar className="text-[20px] text-[gray]" />
                            <FaStar className="text-[20px] text-[gray]" />
                            <FaStar className="text-[20px] text-[gray]" />
                          </div>
                        )}
                      </>
                    )}

                    {review?.type === "depop" && (
                      <>
                        {review?.rating === 5 && (
                          <div className="flex justify-start items-center gap-[3px]">
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                          </div>
                        )}
                        {review?.rating === 4 && (
                          <div className="flex justify-start items-center gap-[3px]">
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[gray]" />
                          </div>
                        )}
                        {review?.rating === 3 && (
                          <div className="flex justify-start items-center gap-[3px]">
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[gray]" />
                            <FaStar className="text-[20px] text-[gray]" />
                          </div>
                        )}
                        {review?.rating === 2 && (
                          <div className="flex justify-start items-center gap-[3px]">
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[gray]" />
                            <FaStar className="text-[20px] text-[gray]" />
                            <FaStar className="text-[20px] text-[gray]" />
                          </div>
                        )}
                        {review?.rating === 1 && (
                          <div className="flex justify-start items-center gap-[3px]">
                            <FaStar className="text-[20px] text-[#fad102]" />
                            <FaStar className="text-[20px] text-[gray]" />
                            <FaStar className="text-[20px] text-[gray]" />
                            <FaStar className="text-[20px] text-[gray]" />
                            <FaStar className="text-[20px] text-[gray]" />
                          </div>
                        )}
                      </>
                    )}

                    <p
                      className={`${
                        review?.comment?.length > 130
                          ? "text-[12px] mt-[10px]"
                          : "text-[15px] mt-[10px]"
                      }`}
                    >
                      {review?.comment}
                    </p>
                  </div>
                  {review?.type === "ebay" && (
                    <img className="h-[20px]" src={ebay} alt="ebay" />
                  )}
                  {review?.type === "depop" && (
                    <img
                      className="h-[16px] mt-[10px]"
                      src={depop}
                      alt="depop"
                    />
                  )}
                  {review?.type === "google" && (
                    <img
                      className="h-[26px] mt-[10px]"
                      src={"/google.png"}
                      alt="google"
                    />
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
