import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { RxCross2 } from "react-icons/rx";
import { Circles } from "react-loader-spinner";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import CheckedIcon from "../../assets/Icons/svg/icon-checked-sign.svg";
import { baseUrl } from "../../services/api";
import { useCheckCouponMutation } from "../../services/couponApi";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const PaymentForm = () => {
  const [couponLoading, setCouponLoading] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const { user } = useContext(UserContext);
  const [phone, setPhone] = useState("");
  const [postCode, setPostCode] = useState("");
  const [loading, setLoading] = useState(false);
  const cartProducts = useSelector((state) => state.cart.cart);
  const [billing, setBilling] = useState({
    firstName: "",
    lastName: "",
    city: "",
    streetAddress: "",
    country: "",
    state: "",
    email: "",
  });
  const [shipping, setShipping] = useState({
    firstName: "",
    lastName: "",
    city: "",
    streetAddress: "",
    country: "",
    state: "",
    email: "",
  });
  const [couponValid, setCouponValid] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [Sphone, setSPhone] = useState("");
  const [postSCode, setPostSCode] = useState("");
  const [isSValid, setSValid] = useState(true);
  const [checkCouponCode] = useCheckCouponMutation();
  const [couponError, setCouponError] = useState("");
  const [couponSuccess, setCouponSuccess] = useState("");
  const [couponDetails, setCouponDetails] = useState({});

  const [expanded, setExpanded] = useState("panel1");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const cart = useSelector((state) => state.cart.cart);

  useEffect(() => {
    const total = cart?.reduce((total, item) => {
      if (item?.category === "fashion" && item?.subCategory === "clothes") {
        total = total + item?.counter?.quantity * item?.price;
      } else {
        total = total + item?.count * item?.price;
      }
      return total;
    }, 0);
    setTotalPrice(total);
    let totalShip = 0;
    let catA = 0;
    let catB = 0;
    let catC = 0;
    let catD = 0;
    cart?.forEach((c) => {
      if (c?.shipping === 2.5) {
        if (c?.category === "fashion" && c?.subCategory === "clothes") {
          catD = catD + c?.counter?.quantity;
        } else {
          catD = catD + c?.count;
        }
      } else if (c?.shipping === 3.99) {
        if (c?.category === "fashion" && c?.subCategory === "clothes") {
          catA = catA + c?.counter?.quantity;
        } else {
          catA = catA + c?.count;
        }
      } else if (c?.shipping === 7.49) {
        if (c?.category === "fashion" && c?.subCategory === "clothes") {
          catB = catB + c?.counter?.quantity;
        } else {
          catB = catB + c?.count;
        }
      } else if (c?.shipping === 12.95) {
        if (c?.category === "fashion" && c?.subCategory === "clothes") {
          catC = catC + c?.counter?.quantity;
        } else {
          catC = catC + c?.count;
        }
      }
    });
    if (shipping?.country?.toLowerCase() === "gb") {
      if (catD > 0) {
        if (catD >= 5) {
          totalShip = totalShip + 3.99;
        } else {
          totalShip = totalShip + 2.5;
        }
      }
      if (catA > 0) {
        if (catA >= 5) {
          totalShip = totalShip + 7.49;
        } else {
          totalShip = totalShip + 3.99;
        }
      }
      if (catB > 0) {
        if (catB >= 2) {
          totalShip = totalShip + 12.95;
        } else {
          totalShip = totalShip + 7.49;
        }
      }
      if (catC > 0) {
        totalShip = totalShip + 12.95;
      }
    } else {
      if (catD > 0) {
        if (catD >= 5) {
          totalShip = totalShip + 35;
        } else {
          totalShip = totalShip + 15;
        }
      }
      if (catA > 0) {
        if (catA >= 5) {
          totalShip = totalShip + 45;
        } else {
          totalShip = totalShip + 35;
        }
      }
      if (catB > 0) {
        if (catB >= 2) {
          totalShip = totalShip + 55;
        } else {
          totalShip = totalShip + 45;
        }
      }
      if (catC > 0) {
        totalShip = totalShip + 55;
      }
    }
    setShippingCharge(totalShip);
  }, [cart, shipping?.country]);

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleChange = (e) => {
    setBilling({ ...billing, [e.target.name]: e.target.value });
  };

  const handleShippingChange = (e) => {
    setShipping({ ...shipping, [e.target.name]: e.target.value });
  };

  const validatePostcode = (input) => {
    const regex = /^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/i;
    return regex.test(input);
  };

  const handlePostCode = (e) => {
    const inputValue = e.target.value;
    setPostCode(inputValue);
    setIsValid(validatePostcode(inputValue));
  };

  const handleSPostSCode = (e) => {
    const inputValue = e.target.value;
    setPostSCode(inputValue);
    setSValid(validatePostcode(inputValue));
  };

  const handleMarkSame = (e) => {
    if (e.target.checked === true) {
      setShipping(billing);
      setSPhone(phone);
      setPostSCode(postCode);
    } else {
      setShipping({
        firstName: "",
        lastName: "",
        city: "",
        streetAddress: "",
        country: "",
        state: "",
      });
      setSPhone("");
      setPostSCode("");
    }
  };
  const handleToken = async () => {
    const data = {
      billing: {
        ...billing,
        phone: phone,
        postCode: postCode,
      },
      products: cartProducts,
      userId: user?._id,
    };
    if (couponValid) {
      if (totalPrice < 50) {
        setLoading(true);
        await axios
          .post(baseUrl + "/orders/create-payment-intent", {
            amount:
              (
                totalPrice +
                shippingCharge -
                (totalPrice + shippingCharge) * (couponDetails?.discount / 100)
              ).toFixed(2) * 100,
          })
          .then((res) => {
            if (res?.data?.status) {
              console.log(res?.data);
              localStorage.setItem("paymentDetails", JSON.stringify(data));
              localStorage.setItem(
                "shippingDetails",
                JSON.stringify({ ...shipping, Sphone, postSCode })
              );
              localStorage.setItem(
                "price",
                JSON.stringify({ totalPrice, shippingCharge, couponDetails })
              );
              navigate(`/checkout/${res?.data?.clientSecret}`);
            } else {
              console.log(res?.data?.message);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(true);
        await axios
          .post(baseUrl + "/orders/create-payment-intent", {
            amount:
              (
                totalPrice -
                totalPrice * (couponDetails?.discount / 100)
              ).toFixed(2) * 100,
          })
          .then((res) => {
            if (res?.data?.status) {
              console.log(res?.data);
              localStorage.setItem("paymentDetails", JSON.stringify(data));
              localStorage.setItem(
                "shippingDetails",
                JSON.stringify({ ...shipping, Sphone, postSCode })
              );
              localStorage.setItem(
                "price",
                JSON.stringify({ totalPrice, shippingCharge: 0, couponDetails })
              );
              navigate(`/checkout/${res?.data?.clientSecret}`);
            } else {
              console.log(res?.data?.message);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setLoading(true);
      if (totalPrice < 50) {
        await axios
          .post(baseUrl + "/orders/create-payment-intent", {
            amount: (totalPrice + shippingCharge).toFixed(2) * 100,
          })
          .then((res) => {
            if (res?.data?.status) {
              console.log(res?.data);
              localStorage.setItem("paymentDetails", JSON.stringify(data));
              localStorage.setItem(
                "shippingDetails",
                JSON.stringify({ ...shipping, Sphone, postSCode })
              );
              localStorage.setItem(
                "price",
                JSON.stringify({ totalPrice, shippingCharge, couponDetails })
              );
              navigate(`/checkout/${res?.data?.clientSecret}`);
            } else {
              console.log(res?.data?.message);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        await axios
          .post(baseUrl + "/orders/create-payment-intent", {
            amount: totalPrice.toFixed(2) * 100,
          })
          .then((res) => {
            if (res?.data?.status) {
              console.log(res?.data);
              localStorage.setItem("paymentDetails", JSON.stringify(data));
              localStorage.setItem(
                "shippingDetails",
                JSON.stringify({ ...shipping, Sphone, postSCode })
              );
              localStorage.setItem(
                "price",
                JSON.stringify({ totalPrice, shippingCharge: 0, couponDetails })
              );
              navigate(`/checkout/${res?.data?.clientSecret}`);
            } else {
              console.log(res?.data?.message);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      // closeModal();
    }
  };
  const checkCoupon = () => {
    if (couponCode) {
      setCouponLoading(true);
      checkCouponCode({ couponCode: couponCode }).then((res) => {
        console.log(res.data);
        if (res?.data?.status) {
          setCouponLoading(false);
          setCouponSuccess(res?.data?.message);
          setCouponDetails(res?.data?.data);
          setCouponValid(true);
          setCouponError("");
        } else {
          setCouponLoading(false);
          setCouponError(res?.data?.message);
          setCouponSuccess("");
          setCouponDetails({});
          setCouponValid(false);
        }
      });
    } else {
      setCouponError("Please add a coupon code first!");
    }
  };

  console.log("cart", cart);
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-[30px] max-w-[1440px] min-h-[60vh] mx-auto">
      {/* Left Side - User Information */}
      <div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChangeAccordion("panel1")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>Billing Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="bg-[#f7f7f7] p-[15px] rounded-[4px]">
              <h3 className="text-[20px] font-bold text-start mb-[15px]">
                Billing Details
              </h3>
              <div className="grid grid-cols-2 gap-[25px]">
                <div className="mb-[11px]">
                  <label className="text-[14px] text-gray-600">
                    First Name
                  </label>
                  <input
                    className="w-[100%] border-[1px] border-gray-300 text-[16px] p-[8px] px-[12px] text-[#000] bg-[#f9f9f9d0] rounded-[20px] outline-none"
                    placeholder="First Name"
                    value={billing?.firstName}
                    onChange={handleChange}
                    name="firstName"
                  />
                </div>
                <div className="mb-[11px]">
                  <label className="text-[14px] text-gray-600">Last Name</label>
                  <input
                    className="w-[100%] border-[1px] border-gray-300 text-[16px] p-[8px] px-[12px] text-[#000] bg-[#f9f9f9d0] rounded-[20px] outline-none"
                    placeholder="Last Name"
                    name="lastName"
                    value={billing?.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="mb-[11px]">
                <label className="text-[14px] text-gray-600">Email</label>
                <input
                  className="w-[100%] border-[1px] border-gray-300 text-[16px] p-[8px] px-[12px] text-[#000] bg-[#f9f9f9d0] rounded-[20px] outline-none"
                  placeholder="Email"
                  value={billing?.email}
                  onChange={handleChange}
                  name="email"
                />
              </div>
              <div className="mb-[11px]">
                <label className="text-[14px] text-gray-600">
                  Street Address
                </label>
                <input
                  className="w-[100%] border-[1px] border-gray-300 text-[16px] p-[8px] px-[12px] text-[#000] bg-[#f9f9f9d0] rounded-[20px] outline-none"
                  placeholder="Street Address"
                  value={billing?.streetAddress}
                  onChange={handleChange}
                  name="streetAddress"
                />
              </div>
              <div className="grid grid-cols-2 gap-[25px]">
                <div className="mb-[11px]">
                  <label className="text-[14px] text-gray-600">City</label>
                  <input
                    className="w-[100%] border-[1px] border-gray-300 text-[16px] p-[8px] px-[12px] text-[#000] bg-[#f9f9f9d0] rounded-[20px] outline-none"
                    placeholder="City"
                    value={billing?.city}
                    onChange={handleChange}
                    name="city"
                  />
                </div>
                <div className="mb-[11px]">
                  <label className="text-[14px] text-gray-600">Post Code</label>
                  <input
                    className="w-[100%] border-[1px] border-gray-300 text-[16px] p-[8px] px-[12px] text-[#000] bg-[#f9f9f9d0] rounded-[20px] outline-none"
                    placeholder="Post Code"
                    value={postCode}
                    onChange={handlePostCode}
                  />
                  {isValid ? (
                    <></>
                  ) : (
                    <p className="text-red-600  text-[12px]">
                      Invalid UK Postal Code
                    </p>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-[25px]">
                <div className="mb-[11px]">
                  <label className="text-[14px] text-gray-600">State</label>
                  <input
                    className="w-[100%] border-[1px] border-gray-300 text-[16px] p-[8px] px-[12px] text-[#000] bg-[#f9f9f9d0] rounded-[20px] outline-none"
                    placeholder="State"
                    value={billing?.state}
                    onChange={handleChange}
                    name="state"
                  />
                </div>
                <div className="mb-[11px]">
                  <label className="text-[14px] text-gray-600">Country</label>
                  <ReactFlagsSelect
                    selected={billing?.country}
                    onSelect={(code) => {
                      setBilling({ ...billing, country: code });
                    }}
                    searchable
                  />
                </div>
              </div>
              <div className="mb-[11px]">
                <label className="text-[14px] text-gray-600">Phone</label>
                <PhoneInput
                  country={"us"}
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChangeAccordion("panel2")}
          disabled={
            billing?.firstName &&
            billing?.lastName &&
            billing?.city &&
            billing?.streetAddress &&
            phone &&
            postCode &&
            billing?.email
              ? false
              : true
          }
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>Shipping Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="bg-[#f7f7f7] p-[15px] rounded-[5px]">
              <h3 className="text-[20px] font-bold text-start mb-[15px]">
                Shipping Details
              </h3>
              <div className="my-[15px]">
                <FormControlLabel
                  required
                  control={<Checkbox />}
                  label="Shipping Details is same as billing Details"
                  onChange={handleMarkSame}
                />
              </div>

              <div className="grid grid-cols-2 gap-[25px]">
                <div className="mb-[11px]">
                  <label className="text-[14px] text-gray-600">
                    First Name
                  </label>
                  <input
                    className="w-[100%] border-[1px] border-gray-300 text-[16px] p-[8px] px-[12px] text-[#000] bg-[#f9f9f9d0] rounded-[20px] outline-none"
                    placeholder="First Name"
                    value={shipping?.firstName}
                    onChange={handleShippingChange}
                    name="firstName"
                  />
                </div>
                <div className="mb-[11px]">
                  <label className="text-[14px] text-gray-600">Last Name</label>
                  <input
                    className="w-[100%] border-[1px] border-gray-300 text-[16px] p-[8px] px-[12px] text-[#000] bg-[#f9f9f9d0] rounded-[20px] outline-none"
                    placeholder="Last Name"
                    name="lastName"
                    value={shipping?.lastName}
                    onChange={handleShippingChange}
                  />
                </div>
              </div>
              <div className="mb-[11px]">
                <label className="text-[14px] text-gray-600">Email</label>
                <input
                  className="w-[100%] border-[1px] border-gray-300 text-[16px] p-[8px] px-[12px] text-[#000] bg-[#f9f9f9d0] rounded-[20px] outline-none"
                  placeholder="Email"
                  value={shipping?.email}
                  onChange={handleShippingChange}
                  name="email"
                />
              </div>
              <div className="mb-[11px]">
                <label className="text-[14px] text-gray-600">
                  Street Address
                </label>
                <input
                  className="w-[100%] border-[1px] border-gray-300 text-[16px] p-[8px] px-[12px] text-[#000] bg-[#f9f9f9d0] rounded-[20px] outline-none"
                  placeholder="Street Address"
                  value={shipping?.streetAddress}
                  onChange={handleShippingChange}
                  name="streetAddress"
                />
              </div>
              <div className="grid grid-cols-2 gap-[25px]">
                <div className="mb-[11px]">
                  <label className="text-[14px] text-gray-600">City</label>
                  <input
                    className="w-[100%] border-[1px] border-gray-300 text-[16px] p-[8px] px-[12px] text-[#000] bg-[#f9f9f9d0] rounded-[20px] outline-none"
                    placeholder="City"
                    value={shipping?.city}
                    onChange={handleShippingChange}
                    name="city"
                  />
                </div>
                <div className="mb-[11px]">
                  <label className="text-[14px] text-gray-600">Post Code</label>
                  <input
                    className="w-[100%] border-[1px] border-gray-300 text-[16px] p-[8px] px-[12px] text-[#000] bg-[#f9f9f9d0] rounded-[20px] outline-none"
                    placeholder="Post Code"
                    value={postSCode}
                    onChange={handleSPostSCode}
                  />
                  {isSValid ? (
                    <></>
                  ) : (
                    <p className="text-red-600 text-[12px]">
                      Invalid UK Postal Code
                    </p>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-[25px]">
                <div className="mb-[11px]">
                  <label className="text-[14px] text-gray-600">State</label>
                  <input
                    className="w-[100%] border-[1px] border-gray-300 text-[16px] p-[8px] px-[12px] text-[#000] bg-[#f9f9f9d0] rounded-[20px] outline-none"
                    placeholder="State"
                    value={shipping?.state}
                    onChange={handleShippingChange}
                    name="state"
                  />
                </div>
                <div className="mb-[11px]">
                  <label className="text-[14px] text-gray-600">Country</label>
                  {/* <input
                    className="w-[100%] border-[1px] border-gray-300 text-[16px] p-[8px] px-[12px] text-[#000] bg-[#f9f9f9d0] rounded-[20px] outline-none"
                    placeholder="Country"
                    name="country"
                    value={shipping?.country}
                    onChange={handleShippingChange}
                  /> */}
                  <ReactFlagsSelect
                    selected={shipping?.country}
                    onSelect={(code) => {
                      console.log(code);
                      setShipping({ ...shipping, country: code });
                    }}
                    searchable
                  />
                </div>
              </div>
              <div className="mb-[11px]">
                <label className="text-[14px] text-gray-600">Phone</label>
                <PhoneInput
                  country={"us"}
                  value={Sphone}
                  onChange={(phone) => setSPhone(phone)}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      {/* Right Side - Payment Information */}
      <div>
        <div className="h-max bg-white p-[40px] border rounded-md">
          <div className="md:px-[30px]">
            {/* Product Row */}
            <div className="mb-4">
              {cartProducts.map((product) => (
                <div className="flex justify-between items-center mb-[30px]">
                  <div className="flex justify-start items-center gap-[15px]">
                    <img
                      className="h-[60px] w-[60px]"
                      src={product?.images[0]}
                      alt=""
                    />
                    <p>{product?.name}</p>
                  </div>
                  <p className="font-semibold">
                    {product?.category === "clothes" ? (
                      <>
                        {" "}
                        <span className="uppercase">
                          {product?.counter?.name}
                        </span>
                        ={product?.counter?.quantity} x £{product.price}
                      </>
                    ) : (
                      <>
                        {product?.count} x £{product.price}
                      </>
                    )}
                  </p>
                </div>
              ))}
            </div>

            {/* calculation price and amount  */}
            <div className="font-medium">
              <div className="flex flex-row justify-between items-center">
                <p className="text-[16px] font-medium mb-2">Sub-Total</p>
                <p className="text-[22px] font-medium mb-2">
                  £{parseFloat(totalPrice).toFixed(2)}
                </p>
              </div>
              {parseFloat(totalPrice).toFixed(2) < 50 ? (
                <>
                  <div className="flex flex-row justify-between items-center">
                    <p className="text-[16px] font-medium mb-2">Shipping</p>
                    <p className="text-[22px] font-medium mb-2">
                      {shipping?.country ? (
                        <> £{parseFloat(shippingCharge).toFixed(2)}</>
                      ) : (
                        <>
                          <p className="text-[16px] font-medium mb-2">
                            Waiting for country details
                          </p>
                        </>
                      )}
                    </p>
                  </div>

                  <div className="border-t my-4 border-[#A0A0A0]"></div>
                  <div
                    className={`flex justify-between items-center py-[20px] ${
                      couponDetails?.couponCode ? "line-through" : ""
                    }`}
                  >
                    <p>Total:</p>
                    {shipping?.country ? (
                      <>
                        {" "}
                        <p>
                          £{parseFloat(totalPrice + shippingCharge).toFixed(2)}
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="text-[16px] font-medium mb-2">
                          Waiting for country details
                        </p>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-row justify-between items-center">
                    <p className="text-[16px] font-medium mb-2">Shipping</p>
                    <p className="text-[22px] font-medium mb-2">Free</p>
                  </div>

                  <div className="border-t my-4 border-[#A0A0A0]"></div>
                  <div
                    className={`flex justify-between items-center py-[20px] ${
                      couponDetails?.couponCode ? "line-through" : ""
                    }`}
                  >
                    <p>Total:</p>
                    <p>£{parseFloat(totalPrice).toFixed(2)}</p>
                  </div>
                </>
              )}
            </div>
            {shipping?.country && (
              <div>
                <div className="flex flex-col justify-center lg:flex-row lg:justify-between items-center">
                  <div className="w-[100%] mb-[15px] lg:mb-[0] lg:w-[60%] ">
                    <input
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                      className="text-[16px] border-[1px] outline-none px-[10px] py-[8px] w-[100%] rounded"
                      placeholder="Coupon"
                    />
                  </div>
                  <div className="w-[100%] lg:w-[30%]">
                    {couponLoading ? (
                      <button
                        disabled
                        className=" bg-[#DF5645] flex justify-center items-center p-[10px] font-semibold text-[white] tracking-wider rounded w-[100%] lg:w-[180px]"
                      >
                        <Circles
                          height="24"
                          width="24"
                          color="#ffffff"
                          ariaLabel="circles-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </button>
                    ) : (
                      <button
                        onClick={checkCoupon}
                        className=" bg-[#DF5645] p-[10px] font-semibold text-[white] tracking-wider rounded w-[100%] lg:w-[180px]"
                      >
                        Apply Coupon
                      </button>
                    )}
                  </div>
                </div>
                {couponError && (
                  <p className="text-start text-red-600 text-[14px]">
                    {couponError}
                  </p>
                )}
                {couponSuccess && (
                  <p className="text-start text-green-800 text-[14px]">
                    {couponSuccess}
                  </p>
                )}
              </div>
            )}
            {couponValid && (
              <div className="flex justify-between items-center py-[20px]">
                <p>Total With Coupon({couponDetails?.discount}% off):</p>
                {shipping?.country ? (
                  <>
                    {" "}
                    <p>
                      £
                      {(
                        parseFloat(totalPrice + shippingCharge).toFixed(2) -
                        parseFloat(totalPrice + shippingCharge).toFixed(2) *
                          (couponDetails?.discount / 100)
                      ).toFixed(2)}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="text-[16px] font-medium mb-2">
                      Waiting for country details
                    </p>
                  </>
                )}
              </div>
            )}
            {shipping?.city &&
            shipping?.country &&
            shipping?.firstName &&
            shipping?.lastName &&
            shipping?.streetAddress &&
            shipping?.state &&
            shipping?.email &&
            Sphone &&
            postSCode &&
            parseInt(totalPrice) > 0 ? (
              <>
                {loading ? (
                  <button className="w-[100%] rounded-3xl flex justify-center items-center  bg-[#DF5645] p-[10px] font-semibold text-[white] tracking-wider mt-[15px]">
                    <Circles
                      height="24"
                      width="24"
                      color="#ffffff"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </button>
                ) : (
                  <button
                    onClick={() => handleToken(totalPrice)}
                    className="w-[100%] rounded-3xl  bg-[#DF5645] p-[10px] font-semibold text-[white] tracking-wider mt-[15px]"
                  >
                    Proceed to Payment
                  </button>
                )}
              </>
            ) : (
              <>
                <button
                  disabled={true}
                  className="w-[100%] rounded-3xl  bg-[#edada5] p-[10px] font-semibold text-[white] tracking-wider mt-[15px]"
                >
                  Proceed to Payment
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Modal Overlay */}
      {isOpen && (
        <div
          onClick={handleOverlayClick}
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-80 modal-overlay"
        >
          {/* Modal Content */}
          <div className="bg-white rounded-lg relative w-max h-max py-[50px] px-[100px] md:py-[100px] md:px-[200px]">
            {/* Modal Content */}
            <img
              className="h-[90px] w-[90px] mx-auto"
              src={CheckedIcon}
              alt=""
            />
            <p className="font-semibold mt-[20px] text-center">Order Placed</p>
            <button
              onClick={() => {
                setIsOpen(false);
                navigate("/");
              }}
              className="art-bg-primary text-white px-[35px] py-[8px] rounded mt-[15px]"
            >
              Okay
            </button>
            <RxCross2
              onClick={() => setIsOpen(false)}
              className="absolute top-[10px] right-[10px] h-[20px] w-[20px] cursor-pointer"
              style={{ color: "red" }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentForm;
